<template>
  <div class="MarkingTheAlarm">
    <!-- 列表页 -->

    <div class="search-box">
      <div>
        <!-- 考试科目：
        <el-select
          v-model="examPaperId"
          class="subject-name"
          placeholder="请选择"
          @change="checkSubject"
        >
          <template v-for="item in paperList">
            <el-option
              v-if="showSubject(item)"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </template>
        </el-select> -->
        处理状态：
        <el-select v-model="state" placeholder="请选择" @change="checkSubject">
          <el-option
            v-for="item in stateList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        异常试卷类型：
        <el-select v-model="type" placeholder="请选择" @change="checkSubject">
          <el-option
            v-for="item in exceptionTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-button type="primary" plain @click="dialogVisible = true"
        >处理异常报警数据</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="markabnormolListData"
      border
      class="tab-list"
    >
      <el-table-column prop="teacherName" label="报警人" align="center">
      </el-table-column>
      <el-table-column prop="createTime" label="报警时间" align="center">
      </el-table-column>
      <el-table-column prop="questionNum" label="报警题目" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="showQuestionImg(scope.row)">
            {{ scope.row.questionNum }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="异常试卷类型" align="center">
        <template slot-scope="scope">
          {{ scope.row.type | setName(exceptionTypes) }}
        </template>
      </el-table-column>
      <el-table-column prop="questionNum" label="学生（班级）" align="center">
        <template slot-scope="scope">
          {{ scope.row.studentName + "（" + scope.row.classNum + "）" }}
        </template>
      </el-table-column>
      <el-table-column label="查看答题卡" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="showPaper(scope.row)">
            查看答题卡
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="调整区域" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="adjust(scope.row)"> 调整 </el-button>
        </template>
      </el-table-column>
      <el-table-column label="处理" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.state">已处理并保留记录</template>
          <el-button v-else type="text" @click="showIndexPaper(scope.row)"
            >处理</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="466px">
      <span
        >如果有处理阅卷报警后无法抽到的考生试卷，该操作可以使这些试卷能被正常抽到，确认操作吗？</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="allQuestion()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="处理阅卷报警"
      :visible.sync="dialogVisiblePaper"
      width="320px"
    >
      <div>
        处理方式：
        <el-select
          v-model="indexStateType"
          placeholder="请选择"
          style="width: 180px"
        >
          <el-option
            v-for="item in stateTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblePaper = false">取 消</el-button>
        <el-button type="primary" @click="disposeQuestion()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const stateList = [
  {
    value: 0,
    label: "未处理",
  },
  {
    value: 1,
    label: "已处理:保留记录",
  },
];
const stateTypeList = [
  {
    value: 1,
    label: "保留记录，并打回",
  },
  {
    value: 2,
    label: "取消记录，并打回",
  },
  {
    value: 3,
    label: "删除试卷",
  },
];
import { getObj } from "@/core/api/exam/exam";
import {
  markabnormolList,
  // markabnormolStudentInfo,
  markabnormolHandel,
} from "@/core/api/exam/paperMonitor";
import { exceptionTypes } from "@/core/util/constdata.js";
import { showSubject } from "@/core/util/util";
export default {
  name: "MarkingTheAlarm",
  data() {
    return {
      pageIndex: 1,
      pageSize: 50,
      total: 0,
      listLoading: false,
      dialogVisiblePaper: false,
      dialogVisible: false,
      tabKey: 1,
      state: 0,
      examPaperId: "",
      stateTypeList: stateTypeList,
      indexStateType: "",
      stateList: stateList,
      type: "",
      exceptionTypes: [],
      markabnormolListData: [],
      paperList: [],
      btnLoading: false,
      indexStudent: {},
      showStudent: false,
      questionList: [],
      studentInfo: {},
    };
  },
  created() {
    this.examPaperId = Number(this.$route.query.examPaperId);
    this.exceptionTypes = [{ value: "", label: "全部" }].concat(exceptionTypes);
    this.markabnormolList();
    this.getExamInfo();
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.markabnormolList();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.markabnormolList();
      // console.log(`当前页: ${val}`);
    },
    showSubject(v) {
      return showSubject(v, 9);
    },
    goback() {
      this.indexStudent = {};
      this.showStudent = false;
    },
    adjust(item) {
      // this.indexStudent = ;
      // this.showStudent = true;
      let Base64 = require("js-base64").Base64;
      let data = {
        studentId: item.studentId,
        examId: this.$route.query.examId,
        questionNum: item.questionNum,
      };
      let code = Base64.encode(item.studentName);
      data.code = code;
      let query = Object.assign({}, this.$route.query);
      delete query.studentId;
      delete query.questionNum;
      delete query.examId;
      delete query.code;
      Object.assign(data, query);

      this.$router.push({
        name: "MarkStudent",
        query: data,
      });
      // this.markabnormolStudentInfo();
    },
    // 获取考生主观题信息

    // 阅卷报警
    disposeQuestion() {
      let data = {
        id: this.indexPaper.id,
        state: this.indexStateType,
      };
      this.markabnormolHandel(data);
    },
    // 显示试卷
    showIndexPaper(paper) {
      this.indexPaper = paper;
      this.indexStateType = 1;
      this.dialogVisiblePaper = true;
    },
    allQuestion() {
      // 处理异常报警
      let data = {
        examPaperId: this.examPaperId,
        state: 1,
      };
      this.btnLoading = true;
      this.markabnormolHandel(data)
        .then(() => {
          this.dialogVisible;
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    markabnormolHandel(data) {
      this.btnLoading = true;
      markabnormolHandel(data)
        .then(() => {
          // console.log(res);
          this.$message({
            message: "处理成功",
            type: "success",
          });
          this.markabnormolList();
          this.dialogVisiblePaper = false;
          this.btnLoading = false;
          this.dialogVisible = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    showQuestionImg(item) {
      let data = {
        imgUrl: item.questionUrl,
      };
      if (!item.questionUrl) {
        this.$message({
          message: "当前试题没有试题图片！",
          type: "warning",
        });
        return;
      }
      const routeData = this.$router.resolve({
        name: "ShowQuestionImgs",
        query: data,
      });
      window.open(routeData.href, "_blank");
      // console.log(data);
    },
    getExamInfo() {
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
        res.data.data.paperList.map((item) => {
          this.paperList.push({
            value: item.paperId,
            label: item.subjectName,
            subjectId: item.subjectId,
          });
        });
      });
    },
    // 显示试卷
    showPaper(item) {
      if (!item.sheetUrl) {
        this.$message({
          message: "当前试题没有答题卡图片！",
          type: "warning",
        });
        return;
      }
      let data = {
        name: this.examInformation.name,
        studentName: item.studentName,
        subjectName: this.paperList.filter(
          (item) => item.value == this.examPaperId
        )[0].label,
        sheetUrl: item.sheetUrl,
      };
      let Base64 = require("js-base64").Base64;

      const routeData = this.$router.resolve({
        name: "ShowPaperImg",
        query: {
          code: Base64.encode(JSON.stringify(data)),
        },
      });
      window.open(routeData.href, "_blank");
      // console.log(item.sheetUrl);
    },
    checkSubject() {
      this.markabnormolList();
    },
    markabnormolList() {
      let data = {
        examId: this.$route.query.examId,
        state: this.state,
        type: this.type,
        examPaperId: this.examPaperId,
        current: this.pageIndex,
        size: this.pageSize,
      };
      this.listLoading = true;

      markabnormolList(data)
        .then((res) => {
          this.listLoading = false;
          this.markabnormolListData = res.data.data.records;
          this.total = res.data.data.total;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.MarkingTheAlarm {
  padding: 24px;

  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .el-select {
      margin: 0 24px 0 8px;
    }

    .subject-name {
      width: 120px;
    }
  }
}
</style>
